<template>
  <div>
    <b-container class="mt-2">
      <b-card style="padding: 29px 37px" no-body>
        <!-- Breadcrumb -->
        <div class="d-flex flex-wrap justify-content-between mb-2 align-items-center">
          <b-breadcrumb>
            <b-breadcrumb-item>
              POS
            </b-breadcrumb-item>
            <b-breadcrumb-item @click="getCategory">
              All Item
            </b-breadcrumb-item>
            <b-breadcrumb-item v-for="item, index in breadcrumbsSelected" :key="index"
              @click="handleFilterCategory(item)">
              {{ item.name }}
            </b-breadcrumb-item>
          </b-breadcrumb>
          <div class="d-flex align-items-center">
            <span class="mr-1" style="text-wrap: nowrap">Sort by</span>
            <b-form-select class="mr-1" v-model="filterProduct.sort_by" :options="sortByOptions" />
            <div v-if="filterProduct.sort_by">
              <b-form-radio v-model="filterProduct.sort_type" name="sort_type" value="asc"
                class="custom-control-primary">
                Asc
              </b-form-radio>
              <b-form-radio v-model="filterProduct.sort_type" name="sort_type" value="desc"
                class="custom-control-primary">
                Desc
              </b-form-radio>
            </div>
          </div>
        </div>
        <!-- Tab Category -->
        <div class="px-1" style="overflow-x: auto;">
          <ul class="category-wrapper">
            <li>
              <span @click="getCategory">All Item</span>
            </li>
            <li v-for="item, index in optionCategory" :key="index">
              <span @click="handleFilterCategory(item)">{{ item.name }}</span>
            </li>
          </ul>
        </div>
        <!-- List Product -->
        <div class="product--lists" @scroll="onScroll" style="min-height: calc(100vh - 20.5rem) !important;">
          <div class="d-flex flex-wrap">
            <div v-for="(bundle, indexBundle) in bundles" :key="`${indexBundle}-${bundle.name}`" class="product--cards">
              <b-button class="product--card p-0 w-100 h-100" @click="handleAddBundleToCart(bundle)" :disabled="isLoadingEdit" variant="transparent">
                <div class="position-relative">
                  <div class="position-absolute w-100 h-100" style="top: 50%; left: 50%;transform: translate(-50%, -50%);" v-if="isLoadingEdit">
                    <div class="bg-light h-100 d-flex justify-content-center align-items-center">
                      <b-spinner label="Loading..." />
                    </div>
                  </div>
                  <b-img class="product--image" :src="bundle.image" :alt="bundle.name" />
                  <div class="product--detail">
                    <h6 class="product--title">{{ bundle.name }}</h6>
                    <div class="product--price">
                      <span class="text-white font-weight-bold bg-primary">{{ bundle.price | formatAmount }}</span>
                    </div>
                    <div class="product--unit">
                      <span class="text-white font-weight-bold bg-info">Bundle</span>
                    </div>
                  </div>
                </div>
              </b-button>
            </div>
            <div class="product--cards" v-for="(item, index) in products" :key="index">
              <b-overlay :show="item.is_on_stock_opname" rounded="sm">
                <b-button class="product--card p-0 w-100" :disabled="isLoadingEdit" variant="transparent" @click="item.is_default == true ? detailProductDefault(item) : detailProduct(item.uuid)">
                  <div class="position-relative">
                    <div class="position-absolute w-100 h-100" style="top: 50%; left: 50%;transform: translate(-50%, -50%);" v-if="isLoadingEdit">
                      <div class="bg-light h-100 d-flex justify-content-center align-items-center">
                        <b-spinner label="Loading..." />
                      </div>
                    </div>
                    <b-img class="product--image" :src="item.image_url" :alt="item.alias_name" />
                    <div class="product--detail">
                      <h6 class="product--title">{{ item.alias_name || '-' }}</h6>
                      <div class="product--price">
                        <span class="text-white font-weight-bold bg-primary">{{ item.standard_price | formatAmount
                          }}</span>
                      </div>
                      <div class="product--unit">
                        <span class="text-white font-weight-bold bg-info">{{ item.stock }} {{ item.unit }}</span>
                      </div>
                    </div>
                  </div>
                </b-button>
                <template #overlay>
                  <div class="text-center">
                    <!-- <b-icon icon="stopwatch" font-scale="3" animation="cylon" /> -->
                    <feather-icon size="20" icon="ClockIcon" />
                    <p class="mb-0" style="font-size: 0.8rem;">
                      Sedang stok opname...
                    </p>
                  </div>
              </template>
              </b-overlay>
            </div>
          </div>
          <div class="d-flex justify-content-center mb-1" v-if="isLoading">
            <b-spinner variant="primary" />
          </div>
        </div>
      </b-card>
    </b-container>
    <AddProductToCart :key="2" :modal-key="'product'" :result="resultDetail" :form-data="formPayload"
      :create-item-to-cart="createItemToCart" :messages="messages" :loading="isLoading"
      @typeWarehouse="getTypeWarehouse" @payload="getPayload" />

    <b-modal id="modal-add-bundle" centered title="Apakah Anda yakin? " ok-only ok-title="Accept">
      <b-container class="">
        <h5 class="mb-1">Bundle berisi:</h5>
        <div class="table-responsive-sm">
          <table class="table b-table table-fixed">
            <thead>
              <tr>
                <th>Image</th>
                <th>Nama</th>
                <th>Quantity</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, keyBundle) in detailBundle.products" :key="`${keyBundle}-${item.product.name}`">
                <td><b-img rounded="" width="50" :src="item.product.image_url" :key="`${keyBundle}-${item.product.name}`" />
                </td>
                <td>{{ item.product.name || '-' }}</td>
                <td>{{ item.qty || 0 }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- <div class="d-flex" v-for="(item, keyBundle) in detailBundle.products" :key="`${keyBundle}-${item.product.name}`">
          <b-img width="50" :src="detailBundle.image" :alt="item.product.name" rounded />
          <h6>
            {{ item.product.name }} @{{ item.qty }}
          </h6>
        </div> -->
      </b-container>
      <template #modal-footer="{}">
        <div v-if="isLoading">
          <br>
          <b-spinner class="mb-2" variant="primary" /><br>
        </div>
        <b-button variant="success" @click="handleSaveBundleToCart">
          Tambah Bundle
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import _ from 'lodash'
import {
  BTabs, BTab, BCard, BButton, BSpinner, BContainer, BRow, BCol, BImg, BBreadcrumb, BBreadcrumbItem, BFormSelect, BFormRadio, BOverlay,
} from 'bootstrap-vue'
import AddProductToCart from '@/components/Pos2/Modal/AddProductToCart.vue'
import CardProduct from '@/components/Pos2/Card/CardProduct.vue'
import { checkPermission, successNotification, errorNotification, preformatFloat } from '@/auth/utils'

import { mapState } from 'vuex'

export default {
  components: {
    BTabs,
    BTab,
    BCard,
    BButton,
    CardProduct,
    BSpinner,
    AddProductToCart,
    BContainer,
    BRow,
    BCol,
    BImg,
    BBreadcrumb,
    BBreadcrumbItem,
    BFormSelect,
    BFormRadio,
    BOverlay
  },
  setup() {
    return {
      checkPermission, successNotification, errorNotification, preformatFloat,
    }
  },
  data() {
    return {
      isLoading: false,
      isLoadingEdit: false,
      is_favorite: false,
      filter: 'populer',
      result: Object,
      formPayload: {
        delivery_numbers_uuid: [],
        po_so_uuid: '',
        product_uuid: '',
        warehouse_uuid: '',
        qty: 0,
        price: 0,
        discounts: [],
        is_bonus: 0,
      },
      next_page_url: null,
      page: 2,
      products: [],
      bundles: [],
      resultDetail: {},
      messages: '',
      id: '',
      typeWarehouse: '',
      stock: 0,
      userData: JSON.parse(localStorage.getItem('userData')),
      detailBundle: {},
      optionCategory: [],
      breadcrumbsSelected: [],
      filterProduct: {
        per_page: 60,
        item_uuid_with_childs: null,
        sort_type: null,
        sort_by: null,
      },
      selectedSortBy: null,
      selectedSortType: 'asc',
      sortByOptions: [
        { value: null, text: 'Select Sort By' },
        { value: 'alias_name', text: 'Name' },
        { value: 'standard_price', text: 'Price' },
      ],
    }
  },
  computed: mapState({
    item_id: state => state.item.item_id,
  }),
  watch: {
    '$store.state.cashier.userData': {
      handler(value) {
        this.userData = value
      },
      deep: true,
    },
    item_id: {
      handler: _.debounce(function () {
        this.getData()
      }, 300),
      deep: true
    },
    filterProduct: {
      handler: _.debounce(function () {
        this.getData()
      }, 300),
      deep: true,
    }
  },
  mounted() {
    this.userData = JSON.parse(localStorage.getItem('userData'))

    this.getData()
    this.getBundle()
    this.getCategory()
  },
  methods: {
    removeBreadcrumbs(uuid) {
      const index = this.breadcrumbsSelected.findIndex((item) => item.uuid === uuid);
      this.breadcrumbsSelected = this.breadcrumbsSelected.slice(0, index + 1);
    },
    handleFilterCategory(item) {
      const queryParams = {}
      queryParams.parent_uuid = item.uuid
      const isAlreadyAdded = this.breadcrumbsSelected.some(breadcrumb => breadcrumb.uuid === item.uuid);
      this.filterProduct.item_uuid_with_childs = item.uuid

      if (!isAlreadyAdded) {
        this.breadcrumbsSelected.push({
          name: item.name,
          uuid: item.uuid,
          isParent: item.isParent
        });
      } else {
        this.removeBreadcrumbs(item.uuid)
        // this.breadcrumbsSelected.pop();
      }
      this.$store.dispatch('item/getData', { params: queryParams }).then(result => {
        this.optionCategory = result.data.data.data
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
    },
    getCategory() {
      const queryParams = {}
      queryParams.parent_type = 'parent'
      queryParams.get_all = true
      this.filterProduct.item_uuid_with_childs = null

      this.$store.dispatch('item/getData', { params: queryParams }).then(result => {
        this.optionCategory = result.data.data
        this.breadcrumbsSelected = []
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
    },
    detailProductDefault(item) {
      this.resultDetail = item
      this.$emit('item', { item, type: 'product' })
    },
    getTypeWarehouse(value) {
      this.typeWarehouse = value
    },
    getPayload(value) {
      this.formPayload = value
    },
    async createItemToCart() {
      this.isLoading = true
      this.formPayload.qty = typeof this.formPayload.qty === 'number' ? parseFloat(this.formPayload.qty).toString().replace('.', ',') : this.formPayload.qty
      if (this.$store.state.cart.isEdit === false) {
        this.formPayload.product_uuid = this.id
      }
      if (this.typeWarehouse === 'warehouse') {
        delete this.formPayload.po_so_uuid
        delete this.formPayload.delivery_numbers_uuid
      }
      if (!this.typeWarehouse) {
        delete this.formPayload.po_so_uuid
        delete this.formPayload.delivery_numbers_uuid
      }
      const form = this.preparePayload()
      await this.$store.dispatch(`cart/${this.$store.state.cart.isEdit === true ? 'postEditItemToCart' : 'postAddItemToCart'}`, {
        uuid: this.$store.state.cart.isEdit === false ? this.$store.state.cart.cartUuid : this.$store.state.cart.itemUuid,
        payload: form,
      }).then(() => {
        successNotification(this, 'Success', `Produk berhasil ${this.$store.state.cart.isEdit === false ? 'ditambahkan' : 'diubah'} ke cart`)
        this.getActiveCart()
        this.isLoading = false
        this.formPayload = {}
        this.$bvModal.hide('modal-add-product-to-cart-product')
        this.$store.commit('cart/setIsEdit', false)
        // if (!this.userData.finish_tutorial) {
        //   setTimeout(() => {
        //     this.$tours.cashierTour3.start()
        //   }, 500)
        // }
      }).catch(err => {
        // eslint-disable-next-line no-console
        this.isLoading = false
        this.messages = err.response.data.meta.messages
      })
    },
    async detailProduct(id) {
      try {
        this.isLoadingEdit = true;
        this.$store.commit('cart/setIsEdit', false);
        this.formPayload.product_uuid = id;
        this.id = id;
        this.messages = '';

        const result = await this.$store.dispatch('cashier/getData', {
          uuid: `/${id}`,
          params: {
            with_delivery_numbers: 1
          },
        });

        const item = result.data.data;
        this.resultDetail = item;

        this.formPayload = {
          is_bonus: 0,
          price: item.standard_price,
          discounts: item.discounts,
          qty: 0,
          warehouse_uuid: item.stock_in_warehouse.length > 0 ? item.stock_in_warehouse[0].uuid : '',
        };
        this.stock = item.stock_in_warehouse.length > 0 ? item.stock_in_warehouse[0].stock : 0;
        this.typeWarehouse = item.stock_in_warehouse.length > 0 ? item.stock_in_warehouse[0].type : '';
        this.isLoadingEdit = false;
        this.$bvModal.show('modal-add-product-to-cart-product');
      } catch (err) {
        this.isLoadingEdit = false;
        console.error(err);
      }
    },
    async getActiveCart() {
      await this.$store.dispatch('cart/getCart', {
        uuid: this.$route.query.uuid || '',
        params: '',
      }).then(result => {
        const item = result.data.data
        this.$store.commit('cart/setDiscountCart', item.discount_value)
        this.$store.commit('cart/setDiscountType', item.discount_type)
        this.$store.commit('cart/setDiscountTotal', item.discount_total)
        this.$store.commit('cart/setSubtotalCart', item.subtotal)
        this.$store.commit('cart/setTotalPrice', item.total)
        this.$store.commit('cart/setTotalBuy', item.items.length)
        this.$store.commit('cart/setCartUuid', item.uuid)
        this.$store.commit('cart/setResultCartActive', item)
        this.$store.commit('cashier/setProduct', item.items.length)
        localStorage.setItem('cartUuid', item.uuid)
        localStorage.setItem('cash_total_payment', item.total)
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
    },
    preparePayload() {
      const form = new FormData()
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.formPayload) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.formPayload.hasOwnProperty(key) && this.formPayload[key] != null) {
          if (key !== 'price' && key !== 'qty' && key !== 'discounts' && key !== 'delivery_numbers_uuid' && key !== 'warehouse_uuid' && key !== 'supplier') {
            form.append(key, this.formPayload[key])
          }
          if (key === 'price' || key === 'qty') {
            // eslint-disable-next-line radix
            form.append(key, parseFloat(this.preformatFloat(this.formPayload[key].toString())))
          }
        }
      }
      if (this.typeWarehouse === 'warehouse') {
        form.append('warehouse_uuid', this.formPayload.warehouse_uuid)
      }
      if (this.typeWarehouse === 'supplier') {
        form.append('supplier_uuid', this.formPayload.warehouse_uuid)
      }
      for (let index = 0; index < this.formPayload.discounts.length; index++) {
        const element = this.formPayload.discounts[index]
        // eslint-disable-next-line no-restricted-syntax
        for (const key in element) {
          if (Object.hasOwnProperty.call(element, key)) {
            const items = element[key]
            form.append(`discounts[${index}][${key}]`, items)
          }
        }
      }
      if (this.formPayload.delivery_numbers_uuid) {
        for (let index = 0; index < this.formPayload.delivery_numbers_uuid.length; index++) {
          const element = this.formPayload.delivery_numbers_uuid[index]
          form.append('delivery_numbers_uuid[]', element.label)
        }
      }
      return form
    },
    onScroll(event) {
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      const isBottomReached = scrollTop + clientHeight + 1 >= scrollHeight;

      if (isBottomReached && this.next_page_url && !this.isLoading) {
        this.loadMorePagination();
      }
    },
    async loadMorePagination() {
      try {
        this.isLoading = true;
        const queryParams = this.filterProduct;
        queryParams.page = this.page++
        const result = await this.$store.dispatch('cashier/getData', {
          uuid: '',
          params: queryParams,
        });
        this.next_page_url = result.data.data.next_page_url;
        this.products = [...this.products, ...result.data.data.data];
        this.isLoading = false
      } catch (err) {
        console.error(err);  // eslint-disable-line no-console
      } finally {
        this.isLoading = false;
      }
    },
    async getData() {
      try {
        this.isLoading = true;
        const queryParams = this.filterProduct;
        queryParams.with_delivery_numbers = 1
        queryParams.item_id = this.item_id
        const result = await this.$store.dispatch('cashier/getData', {
          uuid: '',
          params: queryParams,
        });
        this.next_page_url = result.data.data.next_page_url;
        this.result = result.data.data;
        this.products = result.data.data.data;
        this.isLoading = false
      } catch (err) {
        console.error(err);  // eslint-disable-line no-console
      } finally {
        this.isLoading = false;
      }
    },
    async handleSaveBundleToCart() {
      const form = new FormData()
      form.append('bundle_uuid', this.detailBundle.uuid)
      try {
        const response = await this.$http.post(`cashier/cart/add-item-bundle/`, form)

        if (response) {
          this.getActiveCart()
          this.detailBundle = {}
          successNotification(this, 'Success', 'Bundle berhasil di tambahkan ke keranjang')
          this.$bvModal.hide('modal-add-bundle')
        }

      } catch (error) {
        console.log(error);
      }
    },
    async handleAddBundleToCart(bundle) {
      this.isLoadingEdit = true
      try {
        const response = await this.$http.get(`cashier/product-bundlings/${bundle.uuid}`)
        const { products } = response.data.data

        this.detailBundle = response.data.data
        this.isLoadingEdit = false
        this.$bvModal.show('modal-add-bundle')
      } catch (error) {
        this.isLoadingEdit = false
      }
    },
    async getBundle() {
      try {
        this.isLoading = true;
        const queryParams = { per_page: 60 };
        const result = await this.$http.get('cashier/product-bundlings', { params: queryParams });
        // this.next_page_url = result.data.data.next_page_url;
        // this.result = result.data.data;
        this.bundles = result.data.data.data;
        this.isLoading = false
      } catch (err) {
        console.error(err);  // eslint-disable-line no-console
      } finally {
        this.isLoading = false;
      }
    }
  },
}
</script>

<style lang="scss">
.category-wrapper {
  display: flex;
  gap: 1rem;
  list-style: none;
  padding-left: 16px;
  border-bottom: 1px solid #ABA4A4;
  width: max-content;
  padding-left: 0;

  >li {
    margin-bottom: 5px;
    position: relative;

    &:first-child {
      &::after {
        content: '';
        border: 2px solid #000;
        left: 0;
        position: absolute;
        bottom: -7px;
        width: -webkit-fill-available;
        border-radius: 7px;
      }
    }

    span {
      cursor: pointer;
    }
  }
}
</style>
